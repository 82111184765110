import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';

// Keyframes for text animation
const slideDown = keyframes`
  from {
    max-height: 0;
    opacity: 0;
  }
  to {
    max-height: 1000px; /* Arbitrarily large to accommodate the text */
    opacity: 1;
  }
`;

const slideUp = keyframes`
  from {
    max-height: 1000px;
    opacity: 1;
  }
  to {
    max-height: 0;
    opacity: 0;
  }
`;

// Container for the entire FAQ section
const FAQWrapper = styled.div`
  padding: 50px 20px;
  max-width: 800px;
  margin: 0 auto;
`;

// Heading for the FAQ section
const FAQHeading = styled.h2`
  font-size: 2.5rem;
  color: #ffffff;
  text-align: center;
  margin-bottom: 40px;
`;

// Each question box
const FAQBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  padding: 15px;
  cursor: pointer;
  border-radius: 20px;
  border: 3px solid rgba(255, 255, 255, 0.5);
  color: #ffffff;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: rgba(114, 114, 114, 0.5);
  }
`;

// Title of each question
const FAQTitle = styled.h3`
  font-size: 1.2rem;
  margin: 0;
`;

// Container for the arrow icon
const ArrowContainer = styled.div`
  transform: ${({ isOpen }) => (isOpen ? 'rotate(180deg)' : 'rotate(0deg)')};
  transition: transform 0.3s ease;
`;

// Animated answer section
const FAQContent = styled.div`
  position: relative;
  max-height: ${({isOpen}) => (isOpen ? '1000px' : '0')};
  overflow: hidden;
  transition: max-height 0.5s ease, opacity 0.5s ease;
  animation: ${({isOpen}) => (isOpen ? slideDown : slideUp)} 0.5s ease forwards;
  padding: ${({isOpen}) => (isOpen ? '15px 20px' : '0 20px')};
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 0 0 20px 20px;
  color: #d3d3d3;
  margin-top: -20px;
  margin-bottom: 20px;
  padding-top: 30px;
  z-index: 1;
  font-style: italic;
  font-weight: 600;

  & li {
    margin-bottom: 15px;
    
    & h4 {
      margin-bottom: 5px;
    }
  }

  & a {
    color: #009ecc;
  }
`;

const FAQ = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
      <FAQWrapper>
        <FAQHeading>Frequently Asked Questions</FAQHeading>
        <div>
          <FAQBox onClick={() => toggleFAQ(0)}>
            <FAQTitle>What is Suite and what does it offer?</FAQTitle>
            <ArrowContainer isOpen={openIndex === 0}>
              <svg width="24" height="24">
                <polygon points="12,16 6,10 18,10" fill="white"/>
              </svg>
            </ArrowContainer>
          </FAQBox>

          <FAQContent isOpen={openIndex === 0}>
            <p>Suite is a crypto project built on the Sui blockchain that provides an array of trading tools for
              cryptocurrency enthusiasts. These tools are integrated into Telegram, making it easy for users to execute
              trades, monitor markets, and receive insights directly within their Telegram chats.</p>
          </FAQContent>
        </div>
        <div>
          <FAQBox onClick={() => toggleFAQ(1)}>
            <FAQTitle>What kind of trading tools does Suite provide?</FAQTitle>
            <ArrowContainer isOpen={openIndex === 1}>
              <svg width="24" height="24">
                <polygon points="12,16 6,10 18,10" fill="white"/>
              </svg>
            </ArrowContainer>
          </FAQBox>

          <FAQContent isOpen={openIndex === 1}>
            <p>Suite offers tools such as sniping bots for quick transactions, portfolio management systems, DCA, limit
              orders, copy trading, newly deployed and migrated tokens channel, and market analytics directly within the
              Telegram platform. These tools cater to both beginner and advanced traders by simplifying complex trading
              strategies.</p>
          </FAQContent>
        </div>
        <div>
          <FAQBox onClick={() => toggleFAQ(2)}>
            <FAQTitle>How does Suite ensure user security?</FAQTitle>
            <ArrowContainer isOpen={openIndex === 2}>
              <svg width="24" height="24">
                <polygon points="12,16 6,10 18,10" fill="white"/>
              </svg>
            </ArrowContainer>
          </FAQBox>

          <FAQContent isOpen={openIndex === 2}>
            <p>Suite uses strongest encryption methods for storing critical user data. Access to all data is strictly
              limited, and private nodes by credible providers are used to ensure swiftness of command execution and
              security of data.</p>
          </FAQContent>
        </div>
        <div>
          <FAQBox onClick={() => toggleFAQ(3)}>
            <FAQTitle>What makes Suite different from other crypto trading tools on Telegram?</FAQTitle>
            <ArrowContainer isOpen={openIndex === 3}>
              <svg width="24" height="24">
                <polygon points="12,16 6,10 18,10" fill="white"/>
              </svg>
            </ArrowContainer>
          </FAQBox>

          <FAQContent isOpen={openIndex === 3}>
            <p>Suite stands out by providing one of the fastest trading experiences on the Sui blockchain, offering a
              significant speed advantage for traders. Additionally, it grants exclusive access to special deploy
              channels, which only support Suite's sniper tool, ensuring users have a unique edge. Features like copy
              trading and volume bots further enhance Suite’s sniper functionality, making it superior to competitors by
              offering both precision and speed in trading.</p>
          </FAQContent>
        </div>
        <div>
          <FAQBox onClick={() => toggleFAQ(4)}>
            <FAQTitle>What benefits do holders receive from owning Suite tokens?</FAQTitle>
            <ArrowContainer isOpen={openIndex === 4}>
              <svg width="24" height="24">
                <polygon points="12,16 6,10 18,10" fill="white"/>
              </svg>
            </ArrowContainer>
          </FAQBox>

          <FAQContent isOpen={openIndex === 4}>
            <p>Holders of Suite tokens benefit from the platform's commitment to sharing a large portion of the revenue
              generated back with the community. This means that as Suite grows and generates more income through its
              trading tools and services, a significant share of that revenue is distributed back to Suite token
              holders, offering them a direct financial incentive for being part of the project.</p>
          </FAQContent>
        </div>
        <div>
          <FAQBox onClick={() => toggleFAQ(5)}>
            <FAQTitle>How can I retrieve my private key from the sniper?</FAQTitle>
            <ArrowContainer isOpen={openIndex === 5}>
              <svg width="24" height="24">
                <polygon points="12,16 6,10 18,10" fill="white"/>
              </svg>
            </ArrowContainer>
          </FAQBox>

          <FAQContent isOpen={openIndex === 5}>
            <p>You cannot retrieve your private key after it's generated and provided to you. It is crucial to save it
              securely when it is given to you, as losing it means you will lose access to your wallet and funds
              permanently. Always store it in a safe and secure place, such as a hardware wallet or encrypted
              storage.</p>
          </FAQContent>
        </div>
        <div>
          <FAQBox onClick={() => toggleFAQ(6)}>
            <FAQTitle>How to buy $SUITE?</FAQTitle>
            <ArrowContainer isOpen={openIndex === 6}>
              <svg width="24" height="24">
                <polygon points="12,16 6,10 18,10" fill="white"/>
              </svg>
            </ArrowContainer>
          </FAQBox>

          <FAQContent isOpen={openIndex === 6}>
            <ul>
              <li>
                <h4>1. Download or Install the SUI Wallet / Extension using this link:</h4>
                <p>- https://suiwallet.com/</p>
              </li>
              <li>
                <h4>2. Bridge your Funds through:</h4>
                <p>- https://changenow.io/</p>
                <p>- https://bridge.sui.io/ (only from ETH)</p>
              </li>
              <li>
                <h4>To Exchange SUI for $SUITE Use:</h4>
                <p>- <a href="https://t.me/SuiSniperBot?start=b6f63f5f-7656-49a5-abc4-52055aa62e73">Our sniper</a> or <a href="https://dex.bluemove.net/token/0x818e9258be113f30316dedd24703df7d2ba27eab925e1bf744166d71c487342a::suite::SUITE/0x2::sui::SUI">DEX</a>
                </p>
              </li>
              <li>
                <h4>$SUITE CA</h4>
                <p>0x818e9258be113f30316dedd24703df7d2ba27eab925e1bf744166d71c487342a::suite::SUITE</p>
              </li>
            </ul>
          </FAQContent>
        </div>
      </FAQWrapper>
  );
};

export default FAQ;
