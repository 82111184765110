import React from 'react';
import styled from 'styled-components';

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr; /* 3 main columns */
  grid-template-rows: auto auto; /* Flexible row heights */
  grid-template-areas:
    "trade block1 telegram"
    "trade block2 telegram"
    "bridge bot smart";
  gap: 20px;
  padding: 40px 80px;

  @media (max-width: 1224px) {
    padding: 40px 40px;
  }

  @media (max-width: 1150px) {
    padding: 40px 10px;
  }

  @media (max-width: 1024px) {
      width: 600px;
      margin: 0 auto;
    grid-template-columns: 1fr; /* Stack everything in 1 column on mobile */
      column-gap: 0;
    grid-template-areas:
      "block1"
      "trade"
      "telegram"
      "bridge"
      "bot"
      "smart";
  }

  @media (max-width: 610px) {
      width: 95vw;
  }
`;

const GridItem = styled.div`
    background-color: rgba(0, 0, 0, 0.8);
    border-radius: 20px;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    grid-area: ${({area}) => area}; /* Assigning grid areas */
    transition: all 0.5s ease;
    
    & .button {
        margin-top: 15px;
        
        &.sniper {
            margin-bottom: 30px;
        }
    }
    
    &:hover {
        background-color: rgba(35, 35, 35, 0.8);
        transform: scale(1.05);
        cursor: pointer;
    }
`;

const FullSizeGridItem = styled.div`
    overflow: hidden;
    position: relative;
    background-color: rgba(0, 0, 0, 0.8);
    border-radius: 20px;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
    padding-top: 20px;
    min-height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    grid-area: ${({area}) => area}; /* Assigning grid areas */
    transition: all 0.5s ease;
    
    &.banner {
        min-height: 200px;
        background: url("/img/Banner.png") center center / cover no-repeat;
        
        @media (max-width: 1024px) {
              display: none;
          }
    }
    
    &:hover {
        background-color: rgba(35, 35, 35, 0.8);
        transform: scale(1.05);
        cursor: pointer;
    }
`;

const GridImage = styled.img`
  max-width: 300px;
  margin-bottom: 20px;
`;

const FullSizeGridImage = styled.img`
  width: 70%;
  margin-top: 20px;

  @media (max-width: 1024px) {
      width: 50%;
  }
`;

const GridHeading = styled.h3`
  font-size: 28px;
  margin-bottom: 10px;
    color: #fff;
`;

const GridText = styled.p`
    font-size: 14px;
    color: #d5d5d5;
`;

const FullSizeGridText = styled.p`
    font-size: 14px;
    color: #d5d5d5;
    margin-bottom: 20px;
`;

const VideoBackground = styled.video`
  position: absolute;
  top: 50%;
  left: 50%;
    transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 7; /* Adjust as needed to layer the video correctly */
  display: block;
    border-radius: 20px;
`;

const InfoGrid = () => {
  return (
    <GridContainer>
      <FullSizeGridItem area="trade">
        <GridHeading>SUI & MovePump BuyBot</GridHeading>
        <FullSizeGridText>Most customizable BuyBot for SUI</FullSizeGridText>
        <a href="https://t.me/SuiBuyTechBot" className="button button-secondary">Connect BuyBot</a>
        <FullSizeGridImage src="/img/BuyBotBuy.png" alt="SUI BuyBot"/>
      </FullSizeGridItem>

      <GridItem area="block1">
        <GridImage src="/img/Swaps.png" alt="Trade" />
        <GridHeading>Sui Sniper Bot</GridHeading>
        <GridText>Fastest trading bot on SUI for all DEXs</GridText>
        <a href="https://t.me/SuiSniperBot" className="button button-secondary">Start Trading</a>
      </GridItem>

      <FullSizeGridItem area="block2">
          <VideoBackground  autoPlay muted loop playsInline>
            <source src="/img/Banner.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </VideoBackground>
        </FullSizeGridItem>

        <FullSizeGridItem area="telegram">
            <GridHeading>Sui Wallet Tracker</GridHeading>
            <FullSizeGridText>Track swaps & transfers on SUI</FullSizeGridText>
            <a href="https://t.me/SuiWalletTrackBot" className="button button-secondary">Track Wallets</a>
            <FullSizeGridImage src="/img/WalletTrackerBot.png" alt="SUI Wallet Tracker Bot"/>
        </FullSizeGridItem>

        <GridItem area="bridge">
            <GridImage src="/img/VolumeBot.png" alt="SUI Volume Bot"/>
            <GridHeading>Sui Volume Bot</GridHeading>
        <GridText>Coming Soon</GridText>
      </GridItem>

      <GridItem area="bot">
        <GridImage src="/img/Trending.png" alt="Trending" />
        <GridHeading>Sui Trending</GridHeading>
        <FullSizeGridText>Discover current trending coins</FullSizeGridText>
        <a href="https://t.me/Trending_Sui" className="button button-secondary">Trending Channel</a>
      </GridItem>

      <GridItem area="smart">
        <GridImage src="/img/BuyBot.png" alt="Smart Delegate" />
        <GridHeading>Sui New Coins&Pairs</GridHeading>
        <FullSizeGridText>Alerts with all needed information</FullSizeGridText>
        <a href="https://t.me/SuiNewAlerts" className="button button-secondary">Join Channel</a>
      </GridItem>
    </GridContainer>
  );
};

export default InfoGrid;
