import logo from './logo.svg';
import './App.css';
import Navigation from "./components/Navigation/Navigation";
import NeuroCanvas from "./components/NeuroCanvas";
import Header from "./components/Header/Header";
import InfoGrid from "./components/InfoGrid/InfoGrid";
import RevenueShare from "./components/RevenueShare/RevenueShare";
import FAQ from "./components/Faq/Faq";
import Footer from "./components/Footer/Footer";

function App() {
  return (
      <>
        <NeuroCanvas />
    <div className="App">
      <Navigation />
      <Header />
      <InfoGrid />
      <RevenueShare />
      <FAQ />
      <Footer />
    </div></>
  );
}

export default App;
