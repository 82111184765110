import React, {useEffect, useState} from 'react';
import styled from 'styled-components';

// Styles for the header container
const HeaderWrapper = styled.div`
  text-align: center;
  margin-top: 100px;
  margin-bottom: 50px;
  padding: 0 20px;
    
    @media (max-width: 1024px) {
        margin-top: -150px;
    }
`;

// Main title (H1) styles
const MainHeading = styled.h1`
  font-size: 4rem;
  color: #ffffff;
  font-weight: 700;
  margin-bottom: 10px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
`;

// Subheading (H2) styles
const SubHeading = styled.h2`
  font-size: 1.5rem;
  color: #d3d3d3;
  font-weight: 400;
  margin-top: 0;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
`;

// Styles for the blocks container
const BlocksContainer = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: 50px;
    
    @media (max-width: 860px) {
        flex-direction: column;
        align-items: center;
      }
`;

// Each individual block styles
const Block = styled.div`
    background-color: rgba(0, 0, 0, 0.8);
  padding: 20px;
  border-radius: 10px;
  width: 30%;
  text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: all 0.5s ease;
    
    
    &:hover {
        background-color: rgba(35, 35, 35, 0.8);
        transform: scale(1.05);
        cursor: pointer;
    }
    
    @media (max-width: 860px) {
        min-width: 600px;
        
        &:not(:last-of-type) {
            margin-bottom: 20px;
        }
      }
        
        @media (max-width: 620px) {
            min-width: 95vw;
          }
    
    &.revenue {
        @media (max-width: 1350px) {
            min-width: 600px;
          }
        
        @media (max-width: 620px) {
            min-width: 95vw;
          }
    }
`;

// Block title styles
const BlockTitle = styled.h3`
  font-size: 2.2rem;
  color: #ffffff;
  margin-bottom: 20px;
`;

// Block title styles
const BlockSubTitle = styled.h4`
  font-size: 1.4rem;
    color: #cccccc;
  margin-bottom: 20px;
`;

// Block title styles
const BlockSubSubTitle = styled.h5`
    font-size: 1rem;
    color: #cccccc;
    margin-bottom: 20px;
`;

// Block title styles
const BlockNote = styled.h5`
    font-size: 1rem;
    font-style: italic;
    color: #949494;
`;

// Button styles (same as your other component's button)
const Button = styled.button`
  padding: 10px 20px;
  font-size: 1rem;
  color: #ffffff;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #0056b3;
  }
`;

const RevenueShare = () => {
  const [revshareData, setRevshareData] = useState(null);
  const [openWallets, setOpenWallets] = useState(null);

  useEffect(() => {
    const url = "https://crypton-be-bk6w.onrender.com/suite-revshare";
    fetch(url)
      .then(response => response.text())
      .then(backendData => {
          const data = JSON.parse(backendData);
          if (data["error"]) {
              console.error("Error loading data.");
          } else {
              console.log(data);
              setRevshareData(data);
          }
      })
      .catch(error => console.error("Error loading data."));
  }, []);

  const handleOpenWallets = (wallets) => () => {
    setOpenWallets(wallets);
  };

  const LoadingBox = () => (
    <div className="loading-box"></div>
  );

  const WalletPopup = ({closePopup}) => (
      <>
        <div className="wallet-popup-overlay" onClick={closePopup}></div>
        <div className="wallet-popup">
            <h2>Wallets</h2>
          <ul>
            {openWallets.map((wallet, index) => (
              <li key={index} className="wallet-popup-item">
                <a href={`https://suiscan.xyz/mainnet/account/${wallet}`}>{wallet}</a>
              </li>
            ))}
          </ul>
        </div>
      </>
  );

  return (
      <>
        {openWallets && <WalletPopup closePopup={() => setOpenWallets(null)} />}
        <HeaderWrapper>
          <MainHeading>Revenue Share</MainHeading>
          <SubHeading>Hold $SUITE to get a share of our revenue</SubHeading>

          <BlocksContainer>
              <Block>
                  {revshareData && <BlockTitle>{(210956 + parseInt(revshareData['sniper']) + parseInt(revshareData['trending']['total']) + parseInt(revshareData['premium']['total'])).toLocaleString('en-US').replace(/,/g, ",")} SUI</BlockTitle> || <LoadingBox />}
                  <BlockSubTitle>Total Revenue</BlockSubTitle>
                  <a href="https://t.me/SuiSniperBot?start=0x818e9258be113f30316dedd24703df7d2-GRT771" className="button button-secondary">Buy $SUITE</a>
              </Block>
          </BlocksContainer>

          <SubHeading style={{"margin-top": "50px"}}>Current Round (Since Nov 11th)</SubHeading>

          <BlocksContainer>
              <Block>
                  {revshareData && <BlockTitle>{revshareData['sniper']} SUI</BlockTitle> || <LoadingBox />}
                  <BlockSubTitle>Sui Sniper Bot</BlockSubTitle>
                  <a href="https://suiscan.xyz/mainnet/account/0xa43c7eb8837a846225f6270dad803cc5533008db063717468adcb786dd0b825e" className="button button-secondary">Fees Wallet</a>
              </Block>
              <Block>
                  {revshareData && <BlockTitle>{revshareData['trending']['total']} SUI</BlockTitle> || <LoadingBox />}
                  <BlockSubTitle>Sui Trending</BlockSubTitle>
                  {revshareData && <button onClick={handleOpenWallets(revshareData['trending']['wallets'])}
                          className="button button-secondary">See Wallets
                  </button> || null}
              </Block>
              <Block>
                  {revshareData && <BlockTitle>{revshareData['premium']['total']} SUI</BlockTitle> || <LoadingBox />}
                  <BlockSubTitle>Premium Tools</BlockSubTitle>
                  {revshareData && <button onClick={handleOpenWallets(revshareData['premium']['wallets'])}
                          className="button button-secondary">See Wallets
                  </button> || null}

              </Block>
          </BlocksContainer>
            <BlocksContainer>
              <Block className="revenue">
                  <BlockTitle>50% of Total Revenue Will Be Shared with Holders</BlockTitle>
                  <BlockSubSubTitle>Emerald Tier (holding 2%+) - 40%</BlockSubSubTitle>
                  <BlockSubSubTitle>Diamond Tier (holding 1%-1.99%) - 34%</BlockSubSubTitle>
                  <BlockSubSubTitle>Gold Tier (holding 0.1%-0.99%) - 26%</BlockSubSubTitle>
                  <BlockNote>NOTE: 40%, 34%, and 26% represent the percentages from the shared revenue</BlockNote>
              </Block>
          </BlocksContainer>
        </HeaderWrapper>
      </>
  );
};

export default RevenueShare;
