import React from 'react';
import './Footer.css';

function Footer() {
  return (
      <footer className="footer-container">
          <div className="wrapper">
            <div className="footer-column">
              <div className="logo">
                <img src="/img/Suite.png" alt="" className="logo" />
              </div>
              <div className="footer-icons">
                <a href="https://t.me/PortalSuite"><img src="/icons/telegram.svg" alt="Telegram" /></a>
                <a href="https://x.com/Suite_Token"><img src="/icons/twitter.svg" alt="Twitter" /></a>
                <a href="https://docs.suite.tech"><img src="/icons/docs.svg" alt="Docs" /></a>
                <a href="https://dexscreener.com/sui/0xd22e1b12d805daa65f2eadb4db71469be41202b0b644b4a46a46860ae6b68566"><img src="/icons/dexscreener.svg" alt="DexScreener" /></a>
              </div>
            </div>

            <div className="footer-column">
              <h2>For Traders</h2>
              <ul className="footer-links">
                <li><a href="https://t.me/SuiSniperBot">Sniper Bot</a></li>
                <li><a href="https://t.me/SuiWalletTrackBot">Wallet Tracker</a></li>
                <li><a href="https://t.me/Trending_Sui">Trending</a></li>
              </ul>
            </div>

            <div className="footer-column">
              <h2>For Token Devs</h2>
              <ul className="footer-links">
                <li><a href="https://t.me/SuiBuyTechBot">Sui & MovePump BuyBot</a></li>
                <li><a href="https://t.me/SuiTrendingBot">Book Trending</a></li>
                <li><a href="">Volume Bot (soon)</a></li>
              </ul>
            </div>
          </div>
      </footer>
  );
}

export default Footer;
