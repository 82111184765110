import React, {useState} from 'react';
import styled from 'styled-components';

const NavWrapper = styled.nav`
    position: fixed;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #1f1f1f; /* Dark background */
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 24px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15); /* Subtle shadow */
    z-index: 8; /* Ensure it's on top */
    transition: all 0.5s ease;
    min-width: 600px;


    &:hover {
        background-color: #313131;
        left: 51%;
        transform: scale(1.05) translateX(-50.05%);
        cursor: pointer;
    }
    
    @media (max-width: 620px) {
        min-width: 95vw;
        &:hover {
            background-color: #1f1f1f;
            left: 50%;
            transform: translateX(-50%);
        }
    }
`;

const Logo = styled.div`
  font-size: 18px;
  font-weight: 600;
  color: #fff;
  display: flex;
  align-items: center;

  img {
    margin-right: 8px; /* Space between the icon and text */
  }
`;

const NavLinks = styled.ul`
  list-style: none;
  display: flex;
  margin-left: 10px;
  margin-right: 20px;
  padding: 0;

  li {
      &:not(:first-of-type) {
          
    margin-left: 24px;
      }

    a {
      color: #BEBEBE; /* Soft, subtle color for the links */
      text-decoration: none;
      font-size: 16px;
      font-weight: 500;
      transition: color 0.3s;

      &:hover {
        color: #fff; /* On hover, change to white */
      }
    }
  }
`;

const InteractButton = styled.button`
  background-color: white;
  color: #000;
  border-radius: 24px;
  padding: 8px 16px;
  border: none;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s;
    margin-left: 8px;

  &:hover {
    background-color: #f0f0f0;
  }
`;

const Navigation = () => {
    const [menuOpen, setMenuOpen] = useState(false);

    function toggleMenu() {
      setMenuOpen(!menuOpen);
    }

    return (
        <>
            <NavWrapper>
                <Logo>
                    <img src="/img/Suite.png" alt="Logo" height="70"/>
                </Logo>
                <NavLinks className="desktop-nav">
                    <li><a href="https://t.me/PortalSuite">Community</a></li>
                    <li><a href="https://x.com/Suite_Token">Twitter</a></li>
                    <li><a href="https://docs.suite.tech">Docs</a></li>
                </NavLinks>
                <a href="https://dex.bluemove.net/token/0x818e9258be113f30316dedd24703df7d2ba27eab925e1bf744166d71c487342a::suite::SUITE/0x2::sui::SUI"
                   className="button button-secondary desktop-nav">Buy $SUITE</a>
                <button className="burger-menu" onClick={toggleMenu}>
                    {menuOpen ? (
                        <span className="cross">&#10005;</span>  // Render cross when menu is open
                    ) : (
                        <>
                            <div className="bar"></div>
                            <div className="bar"></div>
                            <div className="bar"></div>
                        </>
                    )}
                </button>
            </NavWrapper>

            <div className={`overlay ${menuOpen ? 'show' : ''}`}></div>
            <ul className={`nav-list-mobile ${menuOpen ? 'show' : ''}`}>
                <li>
                    <a href="https://t.me/PortalSuite">Community</a>
                </li>
                <li>
                    <a href="https://x.com/Suite_Token">Twitter</a>
                </li>
                <li>
                    <a href="https://docs.suite.tech">Docs</a>
                </li>
                <li>
                    <a href="https://dex.bluemove.net/token/0x818e9258be113f30316dedd24703df7d2ba27eab925e1bf744166d71c487342a::suite::SUITE/0x2::sui::SUI"
                       className="button button-primary">Buy $SUITE</a>
                </li>
            </ul>
        </>
    )
        ;
};

export default Navigation;