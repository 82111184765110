import React from 'react';
import styled from 'styled-components';

// Styles for the header container
const HeaderWrapper = styled.div`
  text-align: center;
  margin-top: 200px; /* Adjust the top margin as needed */
    margin-bottom: 150px;
  padding: 0 20px;
`;

// Main title (H1) styles
const MainHeading = styled.h1`
  font-size: 4rem; /* Large text */
  color: #ffffff;  /* White text */
  font-weight: 700; /* Bold */
  margin-bottom: 10px; /* Spacing between title and subheading */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Subtle shadow for readability */
    
  @media (max-width: 680px) {
      font-size: 2.5rem;
  }
    
  @media (max-width: 440px) {
      font-size: 2rem;
  }
`;

// Subheading (H2) styles
const SubHeading = styled.h2`
  font-size: 1.5rem; /* Medium text for the subheading */
  color: #d3d3d3;    /* Slightly lighter text for contrast */
  font-weight: 400;  /* Regular weight */
  margin-top: 0;     /* No top margin */
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    
  @media (max-width: 680px) {
      font-size: 1rem;
  }
`;

const Header = () => {
  return (
    <HeaderWrapper>
      <MainHeading>The Ultimate Solution</MainHeading>
      <MainHeading>for Trading on SUI</MainHeading>
      <SubHeading>Elevate your SUI experience to highest level</SubHeading>
    </HeaderWrapper>
  );
};

export default Header;
